import GCC from '../../assets/images/gcc-registered.webp';
import McTimony from '../../assets/images/mctimoney.webp';

const Footer = () => {
  return (
    // <footer className="w-full bg-[#252525] sticky bottom-0 z-[-10] h-auto pb-[30px] pt-[10rem] px-[20px] lg:px-[5rem] flex flex-col items-center">
    <footer className="w-full bg-[#252525] sticky bottom-0 z-[1] h-auto px-[20px] lg:px-[5rem] flex flex-row justify-center flex-wrap items-center py-10">
      <div className="text-[#878c93] max-w-6xl w-full flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center">
        <div className="mr-1">
          <h2 className="text-[#a2a8af] text-[20px] border-slate border-2 w-fit sm:w-max px-2 py-1 my-2 uppercase">
            Kenworthy Chiropractic
          </h2>
          <p className="my-2 text-sm">© 2016. All Rights Reserved.</p>
          <address className="text-[10px] my-1 md:my-2 font-semibold hover:text-[#6bb2b9]">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.co.uk/maps/place/Kenworthy+Chiropractic/@53.7569887,-2.7044251,15z/data=!4m5!3m4!1s0x0:0x2c9877882140f163!8m2!3d53.7569887!4d-2.7044251"
            >
              Preston City Center: 9 Theatre Street, Preston, Lancashire, PR1
              8BQ
            </a>
          </address>
        </div>
        <div className="flex flex-col justify-start my-1 md:my-5 lg:mt-0">
          <h3 className="text-white uppercase">Site Map</h3>
          <ul className="text-[12px] font-semibold w-full lg:p-2 mt-7 flex flex-row flex-wrap">
            <li className="">
              <a
                href="/"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Home
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>
            <li className="">
              <a
                href="pain-relief"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Pain Relief
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="pregnancy-infancy"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Pregnancy & infancy
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="sport-injury"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Sport & injury
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            {/* <li className="">
              <a
                href="other-services"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Other Services
              </a>
            </li> */}

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="fees"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Fees & opening hours
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="video-exercises"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Video Exercises
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="news"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                News
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="faq"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                FAQ
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="meet-the-team"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Meet the Team
              </a>
            </li>

            <li>
              <span>&nbsp; | &nbsp;</span>
            </li>

            <li className="">
              <a
                href="faq"
                className="hover:text-[#846bb9]  ease-in-out duration-200"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex justify-center w-full max-w-5xl py-2 md:py-0 md:justify-end">
        <div className="flex flex-row flex-wrap items-center justify-center">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://threebestrated.co.uk/chiropractors-in-preston"
            style={{ display: 'inline-block', border: 0 }}
          >
            <img
              style={{ width: '100px', display: 'block' }}
              width="100"
              src="https://threebestrated.co.uk/awards/chiropractors-preston-2023-drk.svg"
              alt="Best Chiropractors in Preston"
              loading="lazy"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://mctimoney-chiropractic.org"
            style={{ display: 'inline-block', border: 0 }}
          >
            <img
              className="w-auto h-[40px] md:h-[50px] rounded-lg mx-2"
              src={McTimony}
              alt="McTimony"
              loading="lazy"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://gcc-uk.org"
            style={{ display: 'inline-block', border: 0 }}
          >
            <img
              className="w-auto h-[40px] md:h-[50px] rounded-lg mx-2"
              src={GCC}
              alt="GCC Registered"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
