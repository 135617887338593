import './styles.css';

import {
  AthleatsWithTreatment,
  Benefits,
  HighImpact,
  LowImpact,
} from '../../static/sport-care';
import { DropDownItem, PageTitle } from '../../components';

import BgImage from '../../assets/images/sports.webp';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-awesome-reveal';

const SportsAndInjury = () => {
  return (
    <div className="py-12 bg-white">
      <Helmet>
        <title>Kenworthy Chiropractic | Sport & Injury</title>
      </Helmet>
      <div className="max-w-5xl px-5 mx-auto">
        <PageTitle title="Sport & Injury" />
        <div className="relative px-5 sport-info-container">
          <Fade direction='left' triggerOnce>
            <img
              loading="lazy"
              src={BgImage}
              alt="running"
              className="absolute top-[-130px] right-[-70px] h-[550px] w-auto z-[0] sport-image"
            />
          </Fade>
          <Fade direction='up' triggerOnce>
            <div className="relative mr-[33%] z-[2] text-container">
              <p className="text-gray-600">
                Exercise physiologist Sean Atkins, PhD, estimates that in excess
                of 90% top class athletes use chiropractic care on a regular
                basis to prevent injury and improve performance.
                <a
                  href="/citations#C1"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px] mr-[2px]"
                >
                  <sup>[C1]</sup>
                </a>
              </p>
              <br />
              <p className="text-gray-600">
                Research has shown that chiropractic treatment can improve
                athletic performance by as much as 16.7% over a two week period.
                <a
                  href="/citations#C2"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px] mr-[2px]"
                >
                  <sup>[C2]</sup>
                </a>
                The report also concluded that properly aligned athletes react
                faster, coordinate better, execute fine movements with improved
                accuracy and precision.
              </p>
            </div>
          </Fade>
        </div>
        <Fade direction='up' triggerOnce cascade damping={0.1}>


          <DropDownItem
            data={HighImpact}
            title="High-impact athletes"
            disabled={false}
            grid={false}
            listStyled={false}
            list={false}
          />


          <DropDownItem
            data={LowImpact}
            title="Low-impact athletes"
            disabled={false}
            grid={false}
            listStyled={false}
            list={false}
          />


          <DropDownItem
            listStyled
            data={Benefits}
            title="Potential benefits of chiropractic treatment"
            disabled={false}
            grid={false}
            list={false}
          />


          <div className="mt-8">
            <p className="px-5 mb-4 text-gray-600">
              Chiropractic treatment can also promote physical strength. For
              example, one study, national level judo athletes showed a 16%
              improvement in grip strength after undergoing only three
              chiropractic sessions
              <a
                href="/citations#C3"
                className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
              >
                <sup>[C3]</sup>
              </a>
            </p>

            <DropDownItem
              listStyled
              data={AthleatsWithTreatment}
              title="Athletes that have used chiropractic treatment"
              citationNumber="C3"
              citationLink="/citations"
              disabled={false}
              grid={false}
              list={false}
            />

          </div>
        </Fade>
      </div>
    </div>
  );
};

export default SportsAndInjury;
