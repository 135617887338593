import { FaArrowLeft } from 'react-icons/fa';
import { navLinks } from '../../static/navLinks';
import reviewLogo from '../../assets/images/google-review.jpg';
import { useLocation } from 'react-router';

type props = {
  navStatus: boolean;
  toggleNavStatus: () => void;
};

const SideNavBar = ({ navStatus, toggleNavStatus }: props) => {


  // Triggered by scrolling, if the navStatus is true, toggle the side nav
  window.addEventListener('scroll', () => {
    if (navStatus) {
      toggleNavStatus();
    }
  });

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <div
        className={`overlay-container transition-all duration-300 ease ${navStatus ? 'w-[100%] left-[-20rem] z-[999] opacity-30' : 'w-[0%] z-[0] left-[0rem] opacity-0'
          } h-full top-0  fixed  right-[20rem] shadow-md bg-gray-300`}
        onClick={() => toggleNavStatus()}
      />


      <div
        className={`fixed top-0 right-0 bg-white ease-in-out duration-500 transition-all overflow-y-scroll z-[2] ${navStatus ? ' max-w-80 h-full' : 'max-w-0 overflow-hidden'
          }`}
      >
        <div className="flex flex-col items-center justify-center p-3 text-gray-600 flex-nowrap xs:p-7 text-small w-fit">
          {/* Close Nav Button on Small devices */}
          <div className="absolute left-0 pl-3 sm:hidden top-3 xs:pl-7">
            <button
              onClick={() => toggleNavStatus()}
              className="text-bold text-[25px] text-gray-600 hover:text-primary flex flex-row flex-nowrap justify-center items-center transition-colors duration-200 ease-in"
            >
              <FaArrowLeft /> <span className="text-sm text-bold">Close</span>
            </button>
          </div>

          {/* Menu List */}
          <ul className="w-full p-2 font-normal text-l mt-7">

            {navLinks.map(({ href, text }, index) => (

              <li key={index} className="border-b-[1px] border-gray-500 pb-[10px] mb-[15px] overflow-hidden max-h-[35px] ">
                <a
                  href={`${href}`}
                  className={`${currentPath === href
                    ? 'text-[#846bb9] font-bold pointer-events-none'
                    : 'hover:text-[#846bb9]  ease-in-out duration-200 '
                    }`}
                >
                  {text}
                </a>
              </li>
            ))}

          </ul>
          {/* Booking & Contact Area */}
          <div className="flex flex-col justify-start w-full pt-6 pl-2 overflow-hidden ">
            <p className="mb-2 font-light text-gray-500 text-small max-h-[24px]">
              Booking & Info
            </p>
            <a
              href="tel:01772379520"
              aria-label="Clinic's landline phone number"
              className="text-[#846bb9] hover:text-[#6bb2b9] mb-1 ease-in-out duration-200 max-h-[24px]"
            >
              01772 379520
            </a>
            <a
              href="bookings"
              aria-label="To the booking page"
              className="text-[#846bb9] hover:text-[#6bb2b9] mb-1 ease-in-out duration-200 max-h-[24px]"
            >
              Book Online
            </a>
          </div>
          <div className="flex flex-row flex-wrap items-center justify-center">
            {/* Review Button Area */}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://search.google.com/local/writereview?placeid=ChIJffHSoHtye0gRY_FAIYh3mCw"
              className="flex flex-col items-center w-full my-4"
            >
              <img loading="lazy"
                src={reviewLogo}
                alt="review button"
                className="w-[100px] hover:translate-y-[-10px] transition-all duration-200 ease-in"
              />
            </a>
            {/* Award */}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://threebestrated.co.uk/chiropractors-in-preston"
              className="flex flex-col items-center w-full my-4"
            >
              <img loading="lazy"
                className="w-[150px] hover:translate-y-[-10px] transition-all duration-200 ease-in"
                src="https://threebestrated.co.uk/awards/chiropractors-preston-2023-drk.svg"
                alt="Best Chiropractors in Preston"
              />
            </a>
          </div>
        </div>
      </div >
    </>
  );
};

export default SideNavBar;
