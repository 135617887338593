import React from 'react'

const Loader = ({ logo, textLineOne, textLineTwo }: { logo: string; textLineOne?: string; textLineTwo?: string; }) => {
	return (
		<div className='flex flex-col items-center justify-center w-full my-40 h-fit grow'>
			<img src={logo} alt="loading" className='delay-500 animate-pulse' />
			{textLineOne && (
				<p className='mt-2 w-fit text-bold'>{textLineOne}</p>
			)}
			{textLineTwo && (
				<p className='mb-2 w-fit text-bold'>{textLineTwo}</p>
			)}

		</div>
	)
}

export default Loader