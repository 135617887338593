import { useEffect, useState } from 'react';

const useFetchStaff = (wordPressSiteURL: string,) => {
	const [staff, setStaff] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		const fetchPosts = async () => {
			setError(false);
			try {
				const response = await fetch(`${wordPressSiteURL}/wp-json/wp/v2/staff?acf_format=standard`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();
				setStaff(data);
			} catch (error) {
				setError(true);
				console.error('Failed to fetch posts:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchPosts();
	}, [wordPressSiteURL]);

	return { staff, loading, error }; // Return totalPosts as well
};

export default useFetchStaff;