import {
  CircularNavigationContainer,
  DynamicDays,
  Reviews,
  WelcomeArea,
} from '../../components';

import { Helmet } from 'react-helmet';
import { reviews } from '../../static/reviews';
// import { useEffect, useState } from 'react';
// import clientConfig from '../../client-config';
// import useFetchSpecialDay from '../../hooks/useFetchSpecialDay';

const Home = () => {

  // const [first, setfirst] = useState<any>()

  // const wordPressSiteURL = clientConfig.siteUrl;

  // const { eventDay } = useFetchSpecialDay(wordPressSiteURL);

  // useEffect(() => {
  //   setfirst(eventDay)
  // }, [eventDay])

  // console.log(first)

  return (
    <>
      <Helmet>
        <title>Kenworthy Chiropractic | Chiropractor Preston</title>
      </Helmet>

      <div className="flex flex-col w-full justify-start items-center bg-[#ffffff]">

        <DynamicDays />

        <CircularNavigationContainer />

        <div className="max-w-5xl">
          {/* <Fade distance="30px" bottom> */}
          <WelcomeArea />
          {/* </Fade> */}
        </div>
        <div className="w-full flex flex-col justify-center items-center bg-[#2ab8b4]">
          <Reviews reviewObj={reviews} />
        </div>
      </div>
    </>
  );
};

export default Home;
