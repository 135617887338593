import { Fade } from "react-awesome-reveal";
import { Helmet } from 'react-helmet';
import { PageTitle } from '../../components';

const Bookings = () => {
  return (
    <div className="py-12 bg-white">
      <Helmet>
        <title>Kenworthy Chiropractic | Booking</title>
      </Helmet>
      <div className="max-w-5xl px-5 mx-auto">
        <PageTitle title="Online Bookings" />
        <div className="px-5">
          <Fade triggerOnce direction='up' cascade damping={0.1}>
            <p className="text-gray-600">
              <span className="font-bold">Please Note:</span> All new patients
              are required to pay for their first appointment when booking
              online. Should an appointment need to be cancelled we will offer a
              full refund on the condition that we are given a minimum of 24hrs
              notice.
            </p>
          </Fade>
        </div>
        <div className="px-5">
          <Fade triggerOnce direction='up' cascade damping={0.1}>
            <iframe
              title="booking"
              src="https://kenworthy-chiropractic.cliniko.com/bookings?embedded=true"
              width="100%"
              height="1000"
            ></iframe>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
