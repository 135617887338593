import { Fade } from "react-awesome-reveal";
import MTT from '../../assets/images/people/johanna-meet-the-team.jpg';
import WelcomeVidMp4 from '../../assets/video/welcome-video.mp4';
import WelcomeVidPic from '../../assets/video/welcome-video-thumb.webp';
import WelcomeVidWebM from '../../assets/video/welcome-video.webm';

const WelcomeArea = () => {
  return (
    <Fade triggerOnce direction='up' cascade damping={0.1}>
      <div className="flex flex-col justify-between px-12 md:flex-row lg:px-0">
        <div className="w-full mx-2 mb-5 md:w-7/12 md:mb-0">
          <h1 className="font-bold text-[40px] mb-2">Welcome</h1>
          <p className="text-gray-600">
            Kenworthy Chiropractic is situated conveniently in the heart of
            Preston City Centre. Our qualified team offers chiropractic
            treatments that are gentle, drug free and effective. Chiropractors
            can treat a wide range of conditions including general, acute and
            chronic back, neck and shoulder pain; musculoskeletal issues
            affecting the joints and posture; sciatica; sports injuries;
            pregnancy related issues; headaches; migraine prevention; lumbago;
            plantar fasciitis and muscle pain amongst many others.
          </p>
          <br />
          <p className="text-gray-600">
            If you require the specialist care of a highly skilled chiropractor
            in the Preston area, or would just like more information give
            Kenworthy Chiropractic a
            <span className="text-[#846bb9] hover:text-[#6bb2b9] mx-1 ease-in-out duration-300">
              <a href="tel:01772379520">call</a>
            </span>
            or schedule an
            <span className="text-[#846bb9] hover:text-[#6bb2b9] mx-1 ease-in-out duration-300">
              <a href="bookings">appointment</a>
            </span>
            .
          </p>
        </div>
        <a
          href="meet-the-team"
          className="flex flex-col items-center justify-center w-full overflow-hidden transition-all duration-200 md:w-4/12 rounded-3xl hover:shadow-xl ease"
        >
          <img src={MTT} alt="meet the team" className="w-full h-full" loading="lazy" />
        </a>
      </div>
      <div className="flex flex-col justify-between mx-12 my-12 lg:mx-0">
        <h2 className="font-bold text-[40px] mb-2">
          What To Expect At Your First Treatment
        </h2>

        <video
          className="rounded-lg to-animate intro-animate-3 fadeInUp animated"
          width="100%"
          poster={WelcomeVidPic}
          controls
        >
          <source src={WelcomeVidMp4} type="video/mp4" />
          <source src={WelcomeVidWebM} type="video/webm" />
        </video>
      </div>
    </Fade>
  );
};

export default WelcomeArea;
