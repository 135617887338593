import { Loader, PageTitle } from '../../components';


import { Helmet } from 'react-helmet';
import TeamMembers from '../../components/Team/TeamMembers/TeamMembers';
import clientConfig from '../../client-config';
import useFetchStaff from '../../hooks/useFetchStaff';
import logo from '../../assets/images/logo.webp';


const MeetTheTeam = () => {
  const wordPressSiteURL = clientConfig.siteUrl;

  const { staff, loading, error } = useFetchStaff(wordPressSiteURL);


  return (
    <div className="py-12 bg-white">
      {/* Meta */}
      <Helmet>
        <title>Kenworthy Chiropractic | Meet The Team</title>
      </Helmet>

      <div className="max-w-5xl px-2 mx-auto">
        <PageTitle title="Meet The Team" />

        {/* Conditioning for the team loading in */}
        {error ? (
          <Loader logo={logo} textLineOne="The team are still working..." textLineTwo="Please try refreshing the page." />
        ) :
          loading ? (
            <Loader logo={logo} textLineOne="Chiropractors Assemble!" />
          ) : (
            <div className="grid mt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:px-5">
              <TeamMembers team={staff} />
            </div>
          )
        }


      </div>
    </div>
  );
};

export default MeetTheTeam;
