import { GiFilmStrip } from 'react-icons/gi';
import { MdOutlineRssFeed } from 'react-icons/md';
// import { RiPsychotherapyLine } from 'react-icons/ri';
import { RiQuestionMark } from 'react-icons/ri';

export const navLinks = [
	{
		href: '/',
		text: 'Home'
	},
	{
		href: '/pain-relief',
		text: 'Pain Relief'
	},
	{
		href: '/pregnancy-infancy',
		text: 'Pregnancy & Infancy'
	},
	{
		href: '/sport-injury',
		text: 'Sport & Injury'
	},
	// {
	// 	href: '/other-services',
	// 	text: 'Other Services'
	// },
	{
		href: '/fees',
		text: 'Fees & opening hours'
	},
	{
		href: '/video-exercises',
		text: 'Video Exercises'
	},
	{
		href: '/news',
		text: 'News'
	},
	{
		href: '/faq',
		text: 'FAQ'
	},
	{
		href: '/meet-the-team',
		text: 'Meet the Team'
	},
	{
		href: '/bookings',
		text: 'Book Online'
	}
]

export const roundNavLinks = [
	{
		path: "pain-relief",
		icon: "a",
		text: "Pain Relief"
	}
	, {
		path: "pregnancy-infancy",
		icon: "l",
		text: "Pregnancy & Infancy",
	},
	{
		path: "sport-injury",
		icon: "b",
		text: "Sport & Injury"
	},
	// {
	// 	path: "other-services",
	// 	icon: <RiPsychotherapyLine />,
	// 	text: "Other Services",
	// },
	{
		path: 'fees',
		icon: 'h',
		text: "Fees & Opening Hours"
	},
	{
		path: "video-exercises",
		icon: <GiFilmStrip />,
		text: "Video Exercises",
	},
	{
		path: "news",
		icon: <MdOutlineRssFeed />,
		text: "News",
	},
	{
		path: "faq",
		icon: <RiQuestionMark />,
		text: "FAQ",

	},
]