import { Fade } from "react-awesome-reveal";
import SinglePost from '../SinglePost/SinglePost';
import logo from '../../../assets/images/logo.webp';

type Props = {
	posts: Post[];
}

const NewsPage = ({ posts, }: Props) => {
	if (posts.length === 0) {
		return (
			<div className='flex flex-col items-center justify-center w-full h-[calc(100dvh-142px)] grow'>
				<img src={logo} alt="loading" className='' />
				<p className='my-2 w-fit text-bold'>Sorry, No News Yet!</p>
			</div>
		);
	}

	return (
		<div className='grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-2'>
			<Fade triggerOnce direction='up' cascade damping={0.1}>
				{posts.map((post, index) => (
					<SinglePost key={index} post={post} />
				))
				}
			</Fade>
		</div >
	);
}

export default NewsPage