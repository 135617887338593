import 'react-awesome-button/dist/styles.css';
import './btn.css';

import { AwesomeButton } from 'react-awesome-button';
import { CornerBanner } from '..';
import { Fade } from "react-awesome-reveal";
import { Spin as Hamburger } from 'hamburger-react';
import { ImLocation } from 'react-icons/im';
import bannerImage from '../../assets/images/home-banner-image.jpg';
import boneAnimation from '../../assets/animations/animation-bone-looped.gif';

type Props = {
  logo: string;
  setMenuHovering: (menuHovering: boolean) => void;
  menuHovering: boolean;
  navStatus: boolean;
  toggleNavStatus: () => void;
};


const HomeNav = ({ logo, setMenuHovering, menuHovering, navStatus, toggleNavStatus }: Props) => {
  return (
    <nav className="flex flex-col w-full justify-start items-center relative h-[700px] sm:h-[680px] md:h-[830px] bg-gradient-to-r from-[#307473bb] to-[#126650ab]">
      <CornerBanner banner={true} />

      <img
        src={bannerImage}
        alt="Kenworthy Chiropractic"
        className="absolute top-0 h-full w-full z-[-1]"
        loading="lazy"
      />
      <div className="flex w-full justify-between items-center max-w-5xl h-[140px] px-5">
        {/* Logo */}
        <div className="flex h-[100px] w-auto">
          <a href="/" className="items-center ">
            <img
              src={logo}
              className="h-[100px] w-auto"
              alt="kenworthy chiropractic"
              loading="lazy"
            />
          </a>
        </div>
        {/* Menu Button */}
        <div className="flex items-center">


          <button
            onMouseEnter={() => {
              setMenuHovering(true);
            }}
            onMouseLeave={() => {
              setMenuHovering(false);
            }}
            onClick={() => toggleNavStatus()}
            type="button"
            className="flex items-center text-xl font-bold text-white h-fit"
          >
            <p className="hidden text-xl font-bold text-white cursor-pointer md:inline">
              Menu &nbsp;
            </p>

            {menuHovering && !navStatus ? (
              <Hamburger
                toggled={navStatus}
                toggle={toggleNavStatus}
                size={40}
                direction="right"
                label="Menu"
              />
            ) : (
              <Hamburger
                toggled={navStatus}
                toggle={toggleNavStatus}
                direction="right"
                label="Menu"
              />
            )}
          </button>
        </div>
      </div>
      {/* Home Menu Info */}
      <div className="flex flex-col w-full max-w-5xl translate-y-28 md:translate-y-1/3 lg:translate-y-1/2 ">
        <div className="flex justify-start mx-10 font-bold text-white columns-1">
          {/* Name */}
          <Fade direction='right' triggerOnce>
            <h1 className="text-[25px] xs:text-[35px] sm:text-[50px] md:text-[68px] font-abz--200">
              Kenworthy Chiropractic...
            </h1>
          </Fade>
        </div>
        <div className="flex justify-end mx-10 font-bold text-white columns-1">
          {/* Slogan */}
          <Fade direction='left' triggerOnce>
            <p className="text-[20px] sm:text-[32px] md:text-[50px] font-abz--200">
              ... Making life better
            </p>
          </Fade>
        </div>
        <Fade direction='up' triggerOnce>
          <div className="mx-10 mt-5">
            <address className="text-xl font-semibold text-white">
              <a
                className="flex"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.co.uk/maps/place/Kenworthy+Chiropractic/@53.7569887,-2.7044251,15z/data=!4m5!3m4!1s0x0:0x2c9877882140f163!8m2!3d53.7569887!4d-2.7044251"
              >
                <span className="mr-1">
                  <ImLocation />
                </span>
                Preston City Centre: 9 Theatre Street, Preston, Lancashire, PR1
                8BQ
              </a>
            </address>
            <div className="mt-5">
              <AwesomeButton type="primary" href="bookings">
                Book Online
              </AwesomeButton>
            </div>
          </div>
        </Fade>
        {/* <Fade right duration={10000} distance="200px"> */}

        <div className="hidden lg:inline xl:inline absolute right-[5rem] bottom-[-60px] ease-in-out duration-300 transition-all">
          <Fade direction='right' duration={10000} triggerOnce>
            <img width="110" src={boneAnimation} alt="bone animation" loading="lazy" />
          </Fade>
        </div>
      </div>
    </nav>
  );
};

export default HomeNav;
