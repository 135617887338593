import './styles.css';

import Star from '../../assets/images/star.svg';

type Props = {
  name: string;
  review: string;
  rating: number;
};

const Review = ({ name, review, rating }: Props) => {
  const handleReviewRating = (rating: number) => {
    switch (rating) {
      case 1:
        return (
          <div className="flex flex-row items-center justify-center">
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
          </div>
        );
      case 2:
        return (
          <div className="flex flex-row items-center justify-center">
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
          </div>
        );

      case 3:
        return (
          <div className="flex flex-row items-center justify-center">
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
          </div>
        );

      case 4:
        return (
          <div className="flex flex-row items-center justify-center">
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
          </div>
        );

      case 5:
        return (
          <div className="flex flex-row items-center justify-center w-full">
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
            <img loading="lazy" className="w-[25px] mx-[1px]" src={Star} alt="star" />
          </div>
        );
      default:
        return <div>N/A</div>;
    }
  };

  return (
    <div className="mx-auto">
      {handleReviewRating(rating)}
      <div className="flex flex-col items-center h-full pt-5 text-white justify-evenly scroll-style">
        <p className="h-auto max-h-[145px]  overflow-hidden hover:overflow-auto text-center">
          {review}
        </p>
        <p className="pt-[10px]">- {name}</p>
      </div>
    </div>
  );
};

export default Review;
