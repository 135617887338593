export const AthleatsWithTreatment = [
  {
    title: 'Usain Bolt',
  },
  {
    title: 'Bradley Wiggins',
  },
  {
    title: "Lewis Hamilton",
  }, {
    title: 'Tiger Woods',
  },
  {
    title: 'Michael Jordan'
  }, {
    title: 'Evander Holyfield',
  }, {
    title: "David Beckham",
  }
]

export const Benefits = [
  {
    title: 'Boosts your immune system',
  },
  {
    title: 'Relieves muscle tension',
  },
  {
    title: "Naturally increase your energy level",
  }
]

export const LowImpact = [
  {
    title: 'Golfers, tennis players and cyclists have found that routine adjustments can help to relieve the strain they place upon their bodies.',
  },
]

export const HighImpact = [
  {
    title: 'Athletes competing in MMA, Rugby, Football, and Ballet find that chiropractic treatment can improve performance and flexibility whilst lowering the risk of injury.',
  },
]