import './styles.css';

import { ReactElement } from 'react';

type Props = {
  path: string;
  icon: string | ReactElement;
  text: string;
};

const RoundPageLink = ({ path, icon, text }: Props) => {
  return (
    <a
      href={path}
      className="flex flex-col items-center group h-[200px] w-[140px] mx-auto"
    >
      <div className="border-2 border-[#846bb9] rounded-full w-[100px] h-[100px] flex items-center justify-center text-[#846bb9] group-hover:text-white group-hover:bg-[#846bb9] group-hover:shadow-2xl shadow=[#846bb9] group-hover:translate-y-[-10px] ease-in-out duration-500 transition-all mb-2">
        <span className={`font-fontello text-[55px] ${icon === 'a' && 'pr-3'}`}>
          {icon}
        </span>
      </div>
      <p className="font-semibold text-center	group-hover:translate-y-[-10px] ease-in-out duration-500 transition-all">
        {text}
      </p>
    </a>
  );
};

export default RoundPageLink;
