import { ACFFeesObject, ACFOpeningObject } from '../types/types';
import { useEffect, useState } from 'react';

const useFetchFeesAndOpenings = (wordPressSiteURL: string, page: number = 1) => {
	const [hours, setHours] = useState<ACFOpeningObject[]>([]);
	const [fees, setFees] = useState<ACFFeesObject[]>([]);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		const fetchOpeningHours = async () => {
			setError(false);
			try {
				const response = await fetch(`${wordPressSiteURL}/wp-json/wp/v2/opening_hours`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();

				setHours(data);

			} catch (error) {
				setError(true);
				console.error('Failed to fetch posts:', error);
			} finally {
				setLoading(false);
			}
		};

		const fetchFees = async () => {
			setError(false);
			try {
				const response = await fetch(`${wordPressSiteURL}/wp-json/wp/v2/fees`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();

				setFees(data);

			} catch (error) {
				setError(true);
				console.error('Failed to fetch posts:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchOpeningHours();
		fetchFees();
	}, [wordPressSiteURL, page]);

	return { hours, fees, loading, error }; // Return totalPosts as well
};

export default useFetchFeesAndOpenings;