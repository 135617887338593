export const Bnsp = [
  {
    id: 1, title: 'Acute nerve-root compression',
  },
  {
    id: 2,
    title: 'Postural issues',
  },
  {
    id: 3,
    title: 'Spondylosis',
  }, {
    id: 4,
    title: 'Rotator cuff tears',
  },
  {
    id: 5,
    title: 'Whiplash'
  }, {
    id: 6,
    title: 'Calcific tendonitis',
  }, {
    id: 7,
    title: 'Scoliosis',
  }, {
    id: 8,
    title: 'Arthritis',
  }, {
    id: 9,
    title: 'Slipped discs',
  }, {
    id: 10,
    title: 'Acromio-clavicular joint separations',
  }
]

export const Pdsj = [
  {
    id: 1,
    title: 'Osteoarthritis',
  },
  {
    id: 2,
    title: 'Shoulder dislocations',
  },
  {
    id: 3,
    title: 'Frozen shoulder',
  }, {
    id: 4,
    title: 'Knee meniscal tears',
  },
  {
    id: 5,
    title: 'Ligament sprains'
  }, {
    id: 6,
    title: 'Glenoid labral tears (shoulder)',
  }, {
    id: 7,
    title: 'Acetabular labral tears (hip)',
  },
]