import { useEffect, useState } from 'react';

const useFetchAllPosts = (wordPressSiteURL: string, page: number = 1) => {
	const [posts, setPosts] = useState<Post[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [totalPosts, setTotalPosts] = useState<number>(0); // New state for total posts

	useEffect(() => {
		const fetchPosts = async () => {
			setError(false);
			try {
				const response = await fetch(`${wordPressSiteURL}/wp-json/wp/v2/posts?page=${page}&_embed`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();
				const total = response.headers.get('X-WP-Total'); // Get the X-WP-Total header

				setPosts(data);
				if (total) setTotalPosts(parseInt(total)); // Set the total posts
			} catch (error) {
				setError(true);
				console.error('Failed to fetch posts:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchPosts();
	}, [wordPressSiteURL, page]);

	return { posts, loading, error, totalPosts }; // Return totalPosts as well
};

export default useFetchAllPosts;