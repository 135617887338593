export const pregnancyInfo = [
  {
    title: 'Potential benefits of chiropractic care during pregnancy:',
    additionalText: 'Chiropractic care can assist in providing an optimal pregnancy. Our Chiropractors use a specialty of adjustments to help balance the pelvis.',

    points: [
      { title: 'Maintaining a healthier pregnancy' },
      { title: 'Controlling symptoms of nausea' },
      { title: 'Reducing the time of labour and delivery' },
      { title: 'Relieving back, neck or joint pain' },
      { title: 'A better chance of avoiding a breech birth' },
      { title: 'A quicker recovery following labour and delivery' },
      { title: 'A lower rate of intervention' },
      { title: 'Feeling good during the pregnancy with increased feelings of well-being' },
      { title: 'Less discomfort during the pregnancy' },
      { title: 'More room in the womb for the baby to move' },
    ],
    citationNumber: 'B2',
  },
  {
    title: 'Statistical Evidence',
    points: [
      { title: '75%-84% of women who receive chiropractic care during pregnancy report relief of back pain' },
      { title: 'Substantial relief from back pain is experienced with an average of 1.8 chiropractic treatments' },
      { title: "Women who seek chiropractic care during pregnancy report a 24% shorter labour and delivery time than those who don’t seek care" },
    ],
    citationNumber: 'B3',
  },

]

export const pregnancyInfancyInfo = [
  {
    title: 'Eight additional reasons parents take their children to see a chiropractor:',
    points: [
      { title: 'To encourage good neural plasticity (brain and nerve development)' },
      { title: 'To support “first-class” nerve communication throughout the body to promote health and well being' },
      { title: "To help strengthen a child’s immunity" },
      { title: 'To help improve breast feeding issues and colic' },
      { title: 'To reduce the impact of modern living on child health' },
      { title: 'To support digestive strength' },
      { title: 'To diminish nerve interference which may impact on a child’s capacity to learn and concentrate' },
      { title: 'To promote body balance (poor posture/asthma/allergies and bed wetting)' },
    ],
    citationNumber: 'B5'
  },
]