import './styles.css';

import { FeesTable, HoursTable, PageTitle } from '../../components';

import { Helmet } from 'react-helmet';
import HipAnimationMp4 from '../../assets/animations/animation-hip.mp4';
import HipAnimationWebM from '../../assets/animations/animation-hip.webm';

import { Fade } from 'react-awesome-reveal';
import clientConfig from '../../client-config';

import useFetchFeesAndOpenings from '../../hooks/useFetchOpeningHours';

const FeesAndOpening = () => {

  const wordPressSiteURL = clientConfig.siteUrl;
  const { hours, fees } = useFetchFeesAndOpenings(wordPressSiteURL);

  return (
    <div className="py-12 bg-white">
      <Helmet>
        <title>Kenworthy Chiropractic | Fees & Opening Hours</title>
      </Helmet>
      <div className="max-w-5xl px-5 mx-auto">
        <PageTitle title="Fees & Opening Hours" />
        <Fade direction='right' cascade damping={0.1} duration={4000} triggerOnce>
          <div className="w-full h-[50px] relative animated--hip-container">
            <video
              className="h-[200px] w-[200px] top-[-180px] right-[-50px] absolute "
              loop
              autoPlay
              muted
              playsInline
            >
              <source src={HipAnimationWebM} type="video/webm" />
              <source src={HipAnimationMp4} type="video/mp4" />
            </video>
          </div>
        </Fade>
        <div className="flex flex-col items-center justify-between w-full max-w-5xl mx-auto mt-8 md:flex-row md:items-stretch">
          <Fade direction='up' triggerOnce cascade damping={0.1} className="flex flex-col items-center justify-between w-full max-w-5xl mx-auto mt-8 md:flex-row md:items-stretch">
            <FeesTable fees={fees} />
            <HoursTable openingHours={hours} />
          </Fade>
        </div>
      </div >
    </div >
  );
};

export default FeesAndOpening;
