import backcareweekmp4 from './special_days/back-care-week.mp4';
import backcareweekposterwebp from './special_days/back-care-week-poster.webp';
import backcareweekwebm from './special_days/back-care-week.webm';
import balanceawarenessweekmp4 from './special_days/balance-awareness-week.mp4';
import balanceawarenessweekposterwebp from './special_days/balance-awareness-week-poster.webp';
import balanceawarenessweekwebm from './special_days/balance-awareness-week.webm';
import bikeweekmp4 from './special_days/bikeweek.mp4';
import bikeweekposterwebp from './special_days/bikeweekposter.webp';
import bikeweekwebm from './special_days/bikeweek.webm';
import bonejointweekmp4 from './special_days/bone-joint-week.mp4';
import bonejointweekposterwebp from './special_days/bone-joint-week-poster.webp';
import bonejointweekwebm from './special_days/bone-joint-week.webm';
import degestivehealthwebp from './special_days/degestivehealth.webp';
import giraffedaymp4 from './special_days/World-Giraffe-Day.mp4';
import giraffedayposterwebp from './special_days/World-Giraffe-Day-poster.webp';
import giraffedaywebm from './special_days/World-Giraffe-Day.webm';
import intewomendaymp4 from './special_days/int-womens-day.mp4';
import intewomendaywebm from './special_days/int-womens-day.webm';
import intewomendaywebp from './special_days/int-womens-day.webp';
import migraineawarenessweekmp4 from './special_days/migraine-awareness-week.mp4';
import migraineawarenessweekposterwebp from './special_days/migraine-awareness-week-poster.webp';
import migraineawarenessweekwebm from './special_days/migraine-awareness-week.webm';
import socialmediakindnessdaymp4 from './special_days/social-media-kindness-day.mp4';
import socialmediakindnessdayposterwebp from './special_days/social-media-kindness-day-poster.webp';
import socialmediakindnessdaywebm from './special_days/social-media-kindness-day.webm';
import worldkindnessdaymp4 from './special_days/world-kindness-day-day.mp4';
import worldkindnessdayposterwebp from './special_days/world-kindness-day-poster.webp';
import worldkindnessdaywebm from './special_days/world-kindness-day-day.webm';

export const specialDays = [{
  day: [29],
  month: 5,
  title: "It's World Digestive Health Day!",
  text: [{
    paragraph: ""
  }],
  textLoc: "left",
  image: degestivehealthwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]"
},
{
  day: [5, 6, 7, 8, 9],
  month: 6,
  title: "It's Bike Week!",
  text: [{
    paragraph: "Whether it’s cycling to work or a pedal around the countryside on a sunny day, lots of us enjoy getting out on our bikes. Maybe you’ve never really been a cyclist but fancy donning a helmet and giving it a go."
  },
  {
    paragraph: "However often you cycle, chiropractic care can help you to really make the most of your time in the saddle. We can help improve nerve function and help those muscles work hard, we can help relieve tight hip flexors and adjust uneven leg lengths all of which can impact your cycling performance."
  },
  {
    paragraph: "So, if you want more pedalling power, book an appointment today."
  }
  ],
  textLoc: "left",
  image: "",
  video: [{
    link: bikeweekmp4,
    type: "mp4"
  },
  {
    link: bikeweekwebm,
    type: "webm"
  }
  ],
  videoPoster: bikeweekposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
  background: "",
  animation: ""
},
{
  day: [21],
  month: 6,
  title: "It's World Giraffe Day!",
  text: [{
    paragraph: "Did you know giraffes have seven vertebrae, the same as us? Theirs are a lot bigger than ours though! Imagine how achy they must get..."
  },
  {
    paragraph: "Now there's not much we can do to help giraffes with stiff necks but we can help you. If it feels like your neck is in a knot and you need a bit of help to get back to feeling on top of the world, book an appointment with us today."
  },
  {
    paragraph: ""
  }
  ],
  textLoc: "left",
  image: "",
  video: [{
    link: giraffedaymp4,
    type: "mp4"
  },
  {
    link: giraffedaywebm,
    type: "webm"
  }
  ],
  videoPoster: giraffedayposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [9, 10, 11, 12, 13],
  month: 10,
  title: "It’s Bone and Joint Week!",
  text: [{
    paragraph: "It’s Bone and Joint Week and a great opportunity to learn more about our musculoskeletal health. "
  },
  {
    paragraph: "When we talk about “musculoskeletal health” we talk about our joints, spines, some connective tissues, muscles and bones and how well they are functioning. "
  },
  {
    paragraph: "This aspect of our health is very important. It impacts our lives in so many different ways from how we socialise and exercise to the what we eat and the way we work. To help us stay healthy and feel good, we need to make sure our musculoskeletal health is the best it can be."
  },
  {
    paragraph: 'Chiropractic care can be a great way to help. Whether it means treating an existing condition or helping to detect or prevent potential issues, we can help you to improve your musculoskeletal health and get back to feeling fantastic. Why not book an appointment today?'
  }
  ],
  textLoc: "left",
  video: [{
    link: bonejointweekmp4,
    type: "mp4"
  },
  {
    link: bonejointweekwebm,
    type: "webm"
  }
  ],
  videoPoster: bonejointweekposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [2, 3, 4, 5, 6],
  month: 10,
  title: "It’s Back Care Awareness Week!",
  text: [{
    paragraph: "Lots of us struggle with back pain, in fact it’s estimated that around 8 million UK adults have back issues (ouch!) and it may come as no surprise to find that our workplaces can really impact our health. "
  },
  {
    paragraph: "Whether you find yourself flopping forward to look at a laptop (have you just sat up straight?) or lifting and carrying heavy loads, our jobs can put a strain on our skeletons and knock us out of line. "
  },
  {
    paragraph: "Chiropractic treatment can help ease your back pain and get you back to feeling fabulous. Why not treat your spine and book an appointment today?"
  }
  ],
  textLoc: "left",
  video: [{
    link: backcareweekmp4,
    type: "mp4"
  },
  {
    link: backcareweekwebm,
    type: "webm"
  }
  ],
  videoPoster: backcareweekposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [9, 10, 11, 12, 13],
  month: 10,
  title: "It’s Bone and Joint Week!",
  text: [{
    paragraph: "It’s Bone and Joint Week and a great opportunity to learn more about our musculoskeletal health. "
  },
  {
    paragraph: "When we talk about “musculoskeletal health” we talk about our joints, spines, some connective tissues, muscles and bones and how well they are functioning. "
  },
  {
    paragraph: "This aspect of our health is very important. It impacts our lives in so many different ways from how we socialise and exercise to the what we eat and the way we work. To help us stay healthy and feel good, we need to make sure our musculoskeletal health is the best it can be."
  },
  {
    paragraph: 'Chiropractic care can be a great way to help. Whether it means treating an existing condition or helping to detect or prevent potential issues, we can help you to improve your musculoskeletal health and get back to feeling fantastic. Why not book an appointment today?'
  }
  ],
  textLoc: "left",
  video: [{
    link: bonejointweekmp4,
    type: "mp4"
  },
  {
    link: bonejointweekwebm,
    type: "webm"
  }
  ],
  videoPoster: bonejointweekposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [2, 3, 4, 5, 6],
  month: 10,
  title: "It's Migraine Awareness Week!",
  textLoc: "left",
  video: [{
    link: migraineawarenessweekmp4,
    type: "mp4"
  },
  {
    link: migraineawarenessweekwebm,
    type: "webm"
  }
  ],
  videoPoster: migraineawarenessweekposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [18, 19, 20, 21, 22],
  month: 9,
  title: "It's Balance Awareness Week!",
  textLoc: "left",
  video: [{
    link: balanceawarenessweekmp4,
    type: "mp4"
  },
  {
    link: balanceawarenessweekwebm,
    type: "webm"
  }
  ],
  videoPoster: balanceawarenessweekposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [9],
  month: 11,
  title: "It's Social Media Kindness Day!",
  text: [{
    paragraph: "Social media can be a daunting place to dwell so let’s spread some positivity and kindness to make someone smile today."
  },

  ],
  textLoc: "left",
  video: [{
    link: socialmediakindnessdaymp4,
    type: "mp4"
  },
  {
    link: socialmediakindnessdaywebm,
    type: "webm"
  }
  ],
  videoPoster: socialmediakindnessdayposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [13],
  month: 11,
  title: "It's World Kindness Day!",
  text: [{
    paragraph: "Being kind costs very little but has a lot of potential to make the world a happier place. Even the smallest of gestures can have a huge impact  so why not try to be a little bit kinder to yourself or perhaps brighten someone else’s day? After all, doing good can do you good!"
  },

  ],
  textLoc: "left",
  video: [{
    link: worldkindnessdaymp4,
    type: "mp4"
  },
  {
    link: worldkindnessdaywebm,
    type: "webm"
  }
  ],
  videoPoster: worldkindnessdayposterwebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]",
},
{
  day: [8],
  month: 3,
  title: "It's International Women's Day!",
  text: [{
    paragraph: "Women today are doing it all, often at the expense of taking care of themselves. Chiropractic treatment is an excellent way of showing yourself some self love and helping with your lower back niggles, upper back pain, headaches, pregnancy related pain and general aches and pains."
  }],
  textLoc: "left",
  video: [{
    link: intewomendaymp4,
    type: "mp4"
  },
  {
    link: intewomendaywebm,
    type: "webm"
  }
  ],
  videoPoster: intewomendaywebp,
  bgColor: "bg-gradient-to-b from-[#856bb9ed]"
},
];



// EACH SPECIAL DAY IS SET UP AS BELOW

// {
//  day: [16, 17, 17],  // Array of number(s)
//  month: 4,  // month as a number
//  title: "", // title of day as string
//  text: [
//     {
//         paragraph: ""  // first paragraph of text string
//     },
//  ],
//  textLoc: "", // sets text location string: (left or right. Center if blank)
//  image: "",  // url for image starts with string: https:www.kenworthychiropractic.com/json_resource/images/special_days/
//  video:[
//     {
//         link:'' //link to video
//         type:'' //type of video. E.g webm, mp4
//     }
// ],
//  ,
//  animation: "", // url for animation starts with string: https:www.kenworthychiropractic.com/json_resource/images/special_days/
//  background:"", // url for background image starts with string: https:www.kenworthychiropractic.com/json_resource/images/special_days/
//  bgColor: "" // classes for settings the background string: e.g bg-gradient-to-b from-[#856bb9ed]
// }