import './styles.css';

import { Fade } from "react-awesome-reveal";

type Props = {
  title: string;
  size?: number;
};

const PageTitle = ({ title, size }: Props) => {
  return (
    <Fade triggerOnce direction='up'>
      <div className="w-fit mb-4 group z-[1]">
        <h1
          className={`text-[${size ? size : '30'}px] md:text-[${size ? size - 20 : '50'
            }px] font-semibold mb-5 mr-10 heading text-gray-700`}
        >
          {title}
        </h1>
        <div className="border-b-4 border-primary w-[40px] group-hover:w-full transition-all ease-ine-out duration-1000"></div>
      </div>
    </Fade>
  );
};

export default PageTitle;
