import { useEffect, useState } from 'react';

import DynamicDay from '../DynamicDay/DynamicDay';
import { specialDays } from '../../assets/specialDays';

declare interface specialDayText {
  paragraph: string;
}

declare interface specialDayVideo {
  link: string;
  type: string;
}

declare interface specialDayProps {
  day: Number[];
  month: Number;
  title: string;
  text?: specialDayText[];
  textLoc?: string;
  image?: string;
  video?: specialDayVideo[];
  videoPoster?: string;
  bgColor?: string;
  background?: string;
  animation?: string;
}

const DynamicDays = () => {
  const [specialDay, setSpecialDay] = useState<specialDayProps>();

  useEffect(() => {
    const today = new Date();

    specialDays.map((day: specialDayProps) => {
      day.day.map((selectedDay: Number) => {
        if (
          `${selectedDay}-${day.month}` ===
          `${today.getDate()}-` + (today.getMonth() + 1)
        ) {
          setSpecialDay(day);
        }
        return null;
      });
      return null;
    });
  }, []);

  if (specialDay === undefined) return null;

  return (
    <div className="w-full h-auto">
      <DynamicDay
        title={specialDay.title} // required title string
        text={specialDay.text} // required text string
        textLoc={specialDay?.textLoc} // optional - default center accepts left or right
        image={specialDay?.image} // optional image path
        video={specialDay?.video} // optional video array
        videoPoster={specialDay?.videoPoster} // optional video poster
        // bgColor={specialDay?.bgColor} //optional - Accepts tailwind properties. prepended with "bg-". Add [] for custom color. Eg. [#fff] will create bg-[#fff] in component
        background={specialDay?.background} // optional - image path
        animation={specialDay?.animation} // optional - animation path
      />
    </div>
  );
};

export default DynamicDays;
