import { useEffect, useState } from 'react';

const useFetchPost = (wordPressSiteURL: string, postSlug: string) => {
	const [post, setPost] = useState<Post>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);


	useEffect(() => {
		const fetchPost = async () => {
			setError(false);
			try {
				const response = await fetch(`${wordPressSiteURL}/wp-json/wp/v2/posts?slug=${postSlug}&_embed`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();

				setPost(data[0]);
			} catch (error) {
				setError(true);
				console.error('Failed to fetch posts:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchPost();
	}, [wordPressSiteURL, postSlug]);

	return { post, loading, error }; // Return totalPosts as well
};

export default useFetchPost;