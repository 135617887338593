export const backNeckShoulder = [
  {
    name: 'Acute nerve-root compression'
  },
  {
    name: 'Whiplash'
  },
  {
    name: 'Slipped discs'
  },
  {
    name: 'Rotator cuff tears'
  },
  {
    name: 'Arthritis'
  },
  {
    name: 'Spondylosis'
  },
  {
    name: 'Scoliosis'
  },
  {
    name: 'Postural issues'
  },
  {
    name: 'Calcific tendonitis'
  },
  {
    name: 'Acromioclavicular joint separations'
  },
]

export const painDiscomfortStiffness = [
  {
    name: 'Osteoarthritis'
  },
  {
    name: 'Ligament sprains'
  },
  {
    name: 'Shoulder dislocations'
  },
  {
    name: 'Glenoid labral tears (shoulder)'
  },
  {
    name: 'Frozen shoulder'
  },
  {
    name: 'Acetabular labral tears (hip)'
  },
  {
    name: 'Knee meniscal tears'
  },
]