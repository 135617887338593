import './styles.css';

import {
  backNeckShoulder,
  painDiscomfortStiffness,
} from '../../static/pain-relief-types';

import { Fade } from "react-awesome-reveal";
import { FiTarget } from 'react-icons/fi';
import { useState } from 'react';

const TabMenu = () => {
  const [selectedButton, setSelectedButton] = useState(1);

  return (
    <div className="p-10 mt-[50px] bg-white">
      <Fade triggerOnce direction='up'>
        <div className="flex w-full h-auto mx-auto border-2 rounded-lg border-slate-300 flex-cols">
          <ul>
            <li>
              <button
                onClick={() => setSelectedButton(1)}
                type="button"
                className={`table-links ${selectedButton === 1 && 'active'}`}
              >
                Back, neck & shoulder pain
              </button>
            </li>
            <li>
              <button
                onClick={() => setSelectedButton(2)}
                type="button"
                className={`table-links ${selectedButton === 2 && 'active'}`}
              >
                Pain, discomfort & stiffness in joints
                <div className="btn-arrow"></div>
              </button>
            </li>
            <li>
              <a
                onClick={() => setSelectedButton(3)}
                href="sport-injury"
                className={`table-links ${selectedButton === 3 && 'active'}`}
              >
                Sport injuries
              </a>
            </li>
            <li>
              <a
                onClick={() => setSelectedButton(4)}
                href="pregnancy-infancy"
                className={`table-links ${selectedButton === 4 && 'active'}`}
              >
                Pregnancy related pain (postnatal & antenatal)
              </a>
            </li>
            <li>
              <button
                onClick={() => setSelectedButton(5)}
                type="button"
                disabled
                className={`table-links ${selectedButton === 5 && 'active'}`}
              >
                Migraine headaches
              </button>
            </li>
            <li>
              <button
                onClick={() => setSelectedButton(6)}
                type="button"
                disabled
                className={`table-links ${selectedButton === 6 && 'active'}`}
              >
                Arthritic pain
              </button>
            </li>
          </ul>
          <div className="w-full h-auto p-5 border-l-2 border-slate-300">
            {/* Btn 1 faq area */}
            <div
              className={`w-full h-full ${selectedButton === 1 ? 'relative' : 'hidden'
                }`}
            >
              <h3 className="mb-2 text-xl font-semibold text-gray-600">
                Back, neck & shoulder pain
              </h3>
              <p className="px-4 pb-4 text-gray-500">Including:</p>
              <div className="px-6 text-gray-600">
                <div className="">
                  <ul className="grid grid-cols-2 mx-auto text-start">
                    {backNeckShoulder.map(({ name }, index: number) => (
                      <li
                        key={index}
                        className="flex flex-row items-start justify-start mb-1"
                      >
                        <span className="">
                          <FiTarget className="h-[24px] mr-1" />
                        </span>

                        <p className="p-0 m-0">{name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* btn2 faq area */}
            <div
              className={`w-full h-full ${selectedButton === 2 ? 'relative' : 'hidden'
                }`}
            >
              <h3 className="mb-2 text-xl font-semibold text-gray-600">
                Pain, discomfort & stiffness in joints
              </h3>
              <p className="px-4 pb-4 text-gray-500">Including:</p>
              <div className="px-6 text-gray-600">
                <div className="">
                  <ul className="grid items-center grid-cols-2 mx-auto">
                    {painDiscomfortStiffness.map(({ name }, index: number) => (
                      <li
                        key={index}
                        className="flex flex-row items-start justify-start mb-1"
                      >
                        <span className="">
                          <FiTarget className="h-[24px] mr-1" />
                        </span>

                        <p className="p-0 m-0">{name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default TabMenu;
