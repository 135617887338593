import { Fade } from "react-awesome-reveal";
import RoundPageLink from './RoundPageLink/RoundPageLink';
import { roundNavLinks } from '../../static/navLinks';

const CircularNavigationContainer = () => {
	return (
		<div className="flex items-center justify-between w-full max-w-5xl">
			{/* <div className="grid items-center justify-center w-full grid-flow-row grid-cols-2 my-12 border-b-2 xs-grid-cols-1 md:grid-cols-4 lg:grid-cols-8"> */}
			<div className="flex flex-row flex-wrap items-center justify-center w-full my-12 border-b-2">
				<Fade triggerOnce direction='up' cascade damping={0.1}>
					{roundNavLinks.map(({ path, icon, text }, index) => (
						<RoundPageLink key={index} path={path} icon={icon} text={text} />
					))}
				</Fade>
			</div>
		</div>)
}

export default CircularNavigationContainer