import { formatDate } from "../../../utils/functions";
import logo from '../../../assets/images/logo.webp';

type Props = {
	post: Post;
}

const SinglePost = ({ post }: Props) => {
	return (
		<div
			key={post.id}
			className='flex flex-col items-start justify-start transition-all rounded-lg h-fit duration-400 ease'
		>
			{/* Image / Title Container */}
			<a href={`/news/post/${post.slug}`}
				className='relative w-full overflow-hidden h-fit group hover:scale-[101%] transition-all duration-200 ease'>
				{/* Image */}
				<img
					src={post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : logo}
					alt={post.title.rendered}
					loading="lazy"
					className={`${post._embedded['wp:featuredmedia'] ? 'rounded-md opacity-100 object-cover object-top w-full h-auto max-w-full aspect-square' : 'bg-primary/60 w-full h-[468px] p-20 rounded-md'}`}
				/>

				<div className='absolute bottom-0 left-0 w-full px-4 pt-2 overflow-hidden text-gray-100 transition-all duration-200 ease group-hover:bg-primary/90 bg-primary/70 rounded-b-md'>
					<h3 className='mb-1 text-2xl font-semibold' dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>
				</div>
			</a>

			{/* Date / excerpt */}
			<div className='w-full h-full py-2 ease'>
				{/* Date */}
				<p className='mb-[10px] px-2 text-sm italic text-gray-500 '>
					<span>
						by {post._embedded.author[0].name}
					</span>
					&nbsp;on&nbsp;
					<span>
						{formatDate(post.date)}
					</span>
				</p>

				{/* excerpt */}
				<p className='px-2 text-gray-600' dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
			</div>
		</div>
	)
}

export default SinglePost