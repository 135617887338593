import { FaCaretDown, FaLink } from 'react-icons/fa';
import { useState } from 'react';

type Props = {
  title: string;
  description: string;
  link: boolean;
  linkTo?: string;
  image?: string;
};

const FaqDropDown = ({ title, description, link, linkTo, image }: Props) => {
  const [fullInfo, setFullInfo] = useState(false);
  const handleFullInfoToggle = () => {
    fullInfo ? setFullInfo(false) : setFullInfo(true);
  };

  return (

    <div
      className={`h-auto ease-in-out duration-1000 transition-all w-full border-2 text-gray-500 border-slate-500 bg-gray-100 rounded-xl flex flex-col overflow-hidden mb-5`}
    >
      <div className={`w-full flex justify-between items-center pt-1 pl-2`}>
        <a
          className={`w-full flex justify-between items-center pt-1 pl-2 text-[#846bb9]`}
          href={linkTo}
          onClick={(e) => {
            !link && e.preventDefault();
            handleFullInfoToggle();
          }}
        >
          <h2 className="text-2xl ">{title}</h2>
          <div>
            {link ? (
              <FaLink className="w-[25px] h-[25px] mr-[5px]" />
            ) : (
              <FaCaretDown
                className={`w-[40px] h-[40px] transition-all ease-in-out duration-1000 ${fullInfo && 'rotate-180'
                  }`}
              />
            )}
          </div>
        </a>
      </div>
      <div
        className={`px-4 ease-in-out duration-1000 transition-all relative mt-2 ${fullInfo
          ? 'max-h-[3000px] ease-in-out duration-1000 transition-all'
          : 'max-h-[0px]'
          }`}
      >
        <div
          className="min-w-full px-1 py-5 mx-auto sm:px-10"
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        ></div>
        {image && <img className="pb-5" src={image} alt="clinic location" loading="lazy" />}
      </div>
    </div>

  );
};

export default FaqDropDown;
