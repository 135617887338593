import './styles.css';

import { Bnsp, Pdsj } from '../../static/injuries';
import { DropDownItem, PageTitle, TabMenu } from '../../components';
import React, { useEffect, useState } from 'react';

import BgImage from '../../assets/images/massage-bg.webp';
import { Helmet } from 'react-helmet';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { Fade } from 'react-awesome-reveal';

const PainRelief = () => {
  const [mobile, setmobile] = useState(false);
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width <= 910) {
      setmobile(true);
    } else {
      setmobile(false);
    }
  }, [width]);

  return (
    <div className="py-12 bg-white">
      <Helmet>
        <title>Kenworthy Chiropractic | Pain Relief</title>
      </Helmet>
      <div className="max-w-5xl px-5 mx-auto">
        <PageTitle title="Pain Relief" />
        <div className="relative px-5 top-info-container">
          <Fade direction='right' triggerOnce>
            <img
              loading="lazy"
              src={BgImage}
              alt="massage"
              className="absolute top-[-90px] right-[-70px] h-[550px] w-auto z-[0] massage-image"
            />
          </Fade>
          <Fade direction='up' triggerOnce>
            <div className="relative mr-[28%] z-[2] text-container">
              <p className="text-gray-600">
                95% of back pain is of a mechanical origin that can be treated
                by a chiropractor with most patients responding well after only
                a few treatments.
                <a
                  href="/citations"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
                >
                  <sup>[1]</sup>
                </a>
              </p>
              <br />
              <p className="text-gray-600">
                A study published in the British Medical Journal 2003 showed
                that chiropractic adjustments for low back and neck pain
                resulted in faster recovery than general medical care.
                <a
                  href="/citations"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
                >
                  <sup>[2]</sup>
                </a>
              </p>
              <br />
              <p className="text-gray-600">
                Chiropractic care is proven to be effective in treating the
                following conditions:
              </p>
            </div>
          </Fade>
        </div>
        {mobile ? (
          <Fade triggerOnce direction='up'>
            <div className="px-5">
              <DropDownItem
                listStyled
                grid
                list
                data={Bnsp}
                title="Back, neck & shoulder pain"
                additionalText="Including:"
                link={''}
                disabled={false}
                citationNumber={''}
                citationLink={''}
              />
              <DropDownItem
                listStyled
                grid
                list
                data={Pdsj}
                title="Pain, discomfort & stiffness in joints"
                additionalText="Including:"
                link={''}
                disabled={false}
                citationNumber={''}
                citationLink={''}
              />
              <DropDownItem
                link="sport-injury"
                title="Sports injuries"
                additionalText={''}
                data={undefined}
                disabled={false}
                grid={false}
                citationNumber={''}
                citationLink={''}
                listStyled={false}
                list={false}
              />
              <DropDownItem
                link="pregnancy-infancy"
                title="Pregnancy related pain (postnatal & antenatal)"
                additionalText={''}
                data={undefined}
                disabled={false}
                grid={false}
                citationNumber={''}
                citationLink={''}
                listStyled={false}
                list={false}
              />
              <DropDownItem
                disabled
                title="Migraine headaches"
                additionalText={''}
                data={undefined}
                link={''}
                grid={false}
                citationNumber={''}
                citationLink={''}
                listStyled={false}
                list={false}
              />
              <DropDownItem
                disabled
                title="Arthriti pain"
                additionalText={''}
                data={undefined}
                link={''}
                grid={false}
                citationNumber={''}
                citationLink={''}
                listStyled={false}
                list={false}
              />
            </div>
          </Fade>
        ) : (
          <TabMenu />
        )}
      </div>
    </div >
  );
};

export default PainRelief;
