export const reviews = [
  {
    name: 'RD',
    review: '"I have had 2 sessions so far here, with Janine and she as changed my life for the better..."',
    rating: 5,
  },
  {
    name: 'AN',
    review: '"Johanna has helped me with my back problems for quite some time now and to be honest I don’t know what I would do without her."',
    rating: 5,
  },
  {
    name: 'RS',
    review: '"I can quite honestly say that after the first visit I went away feeling better both physically and mentally.."',
    rating: 5,
  },
  {
    name: 'SP',
    review:
      '"When I presented with pain in hips, knees and back Janine manipulated, massaged and adjusted the problem areas very effectively."',
    rating: 5,
  },
  {
    name: 'LS',
    review: '"I cannot recommend Janine, and all the staff at Kenworthy Chiropractic, more highly."',
    rating: 5,
  },
  {
    name: 'BC',
    review: `"If your thinking of visiting a Chiropractor, then don't hesitate to call Kenworthy Chiropractic."`,
    rating: 5,
  },
  {
    name: 'CW',
    review: '"Have been struggling with plantar fasciitis in left foot and pain in right hip.First treatment last week and already seeing improvements."',
    rating: 5,
  },
  {
    name: 'JB',
    review: '"I recommend Joanna to all my friends as I have seen firsthand that she can work miracles"',
    rating: 5,
  },
];