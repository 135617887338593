import './styles.css';

import { DropDownItem, PageTitle } from '../../components';
import {
  pregnancyInfancyInfo,
  pregnancyInfo,
} from '../../static/pregnancy-care.js';

import BgImage from '../../assets/images/pregnancy.webp';
import { Fade } from "react-awesome-reveal";
import { Helmet } from 'react-helmet';
import PelvisMp4 from '../../assets/animations/animation-pelvis.mp4';
import PelvisWebM from '../../assets/animations/animation-pelvis.webm';
import { PregnancyProps } from '../../types/types';

const PregnancyAndInfancy = () => {
  return (
    <div className="py-12 bg-white">
      <Helmet>
        <title>Kenworthy Chiropractic | Pregnancy & Infancy</title>
      </Helmet>
      <div className="max-w-5xl px-5 mx-auto">
        <PageTitle title="Pregnancy & Infancy" />
        <div className="relative px-5 pregnancy-info-container">
          <Fade triggerOnce direction='right' delay={200} cascade damping={0.1}>
            <img
              loading="lazy"
              src={BgImage}
              alt="massage"
              className="absolute top-[-280px] right-[-70px] h-[550px] w-auto z-[0] pregnancy-image"
            />
          </Fade>
          <Fade triggerOnce direction='right' cascade damping={0.1}>
            <div className="relative mr-[33%] z-[2] text-container">
              <p className="text-gray-600">
                Chiropractic care is a safe, gentle and drug free form of
                treatment for pregnancy related conditions.
              </p>
              <br />
              <p className="text-gray-600">
                According to the American Pregnancy Association “there are no
                known contraindications to chiropractic care throughout
                pregnancy”.
                <a
                  href="/citations#B1"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
                >
                  <sup>[B1]</sup>
                </a>
              </p>
            </div>
          </Fade>
          <Fade triggerOnce direction='left' duration={5000} cascade damping={0.1}>
            <video className="animated--hips" loop autoPlay muted playsInline>
              <source src={PelvisWebM} type="video/webm" />
              <source src={PelvisMp4} type="video/mp4" />
            </video>
          </Fade>
        </div>
        <div>
          {/* Pregnancy info loop */}
          {pregnancyInfo.map(
            ({
              title,
              points,
              citationNumber,
              additionalText,
            }: PregnancyProps) => (
              <Fade triggerOnce key={title.replace(/\s+/g, '').toLowerCase()} direction='up'>
                < DropDownItem
                  listStyled
                  data={points}
                  title={title}
                  additionalText={additionalText}
                  citationNumber={citationNumber}
                  citationLink="/citations"
                  disabled={false}
                  grid={false}
                />
              </Fade>
            )
          )}
        </div>

        <div className="mt-8">
          <PageTitle title="Infancy" />
          <Fade triggerOnce direction='up' cascade damping={0.1}>
            <p className="px-5 mb-4 text-gray-600">
              A study of 1,250 babies […] found that 95% of the group were not
              only misaligned but also had cervical strain. [The researcher]
              also noted that the infants responded with immediate muscular
              relaxation and had a greater ability to sleep after specific
              spinal adjustments
              <a
                href="/citations#B4"
                className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
              >
                <sup>[B4]</sup>
              </a>
            </p>
          </Fade>
          {/* Infancy Loops */}
          {pregnancyInfancyInfo.map(
            ({
              title,
              points,
              citationNumber,
              additionalText,
            }: PregnancyProps) => (
              <Fade key={title.replace(/\s+/g, '').toLowerCase()} direction='up' triggerOnce>
                < DropDownItem
                  listStyled
                  data={points}
                  title={title}
                  additionalText={additionalText}
                  citationNumber={citationNumber}
                  citationLink="/citations"
                  disabled={false}
                  grid={false}
                />
              </Fade>
            )
          )}
        </div>
      </div>
    </div >
  );
};

export default PregnancyAndInfancy;
