export const painReliefCitations = [

  { citationNumber: 1, link: 'http://www.espina.co.uk/what-we-treat/back-pain-sciatica/', ariaLabel: 'espina: about back pain' },
  { citationNumber: 2, infoText: 'BMJ 2003;326:911', ariaLabel: 'BMJ 2003;326:911' }
]

export const pregnancyInfancyCitations = [

  { citationNumber: 1, link: 'http://americanpregnancy.org/pregnancy-health/chiropractic-care-during-pregnancy/', ariaLabel: 'american pregnancy: chiropractic care during pregnancy' },
  { citationNumber: 2, link: 'https://www.resultsalign.co.uk/pregnancy-and-paediatric-chiropractor/', ariaLabel: 'results align chiropractic information pregnancy' },
  { citationNumber: 3, link: 'http://draperutahchiropractic.com/pregnancy-chiropractic-statistics/', ariaLabel: 'draperutah chiropractic: chiropractic care during pregnancy statistics' },
  { citationNumber: 4, link: 'https://pubmed.ncbi.nlm.nih.gov/1377192/', infoText: 'Fryman VM. Springall P Effect of Osteopathic Medical Management on Neurologic Development in Children JAOA. 1992;92:729', ariaLabel: 'Fryman VM. Springall P Effect of Osteopathic' },
  { citationNumber: 5, infoText: 'Dr. Jennifer Barham-floreani; www.united-chiropractic.org - Issue 42 2015', ariaLabel: 'Dr. Jennifer Barham-floreani; www.united-chiropractic.org - Issue 42 2015' }
]

export const sportInjuryCitations = [
  { citationNumber: 1, link: 'http://www.willowlife.co.uk/Blog/Willow-Blog/June/Chiropractic-in-sport#sthash.rHnSlO0J.dpuf', ariaLabel: 'willow life: Chiropractic in sport' },
  { citationNumber: 2, infoText: 'Drs. Anthony Lauro and Brian Mouch. Journal of Chiropractic research and Clinical Investigation, 1991.', ariaLabel: 'Drs. Anthony Lauro and Brian Mouch. Journal of Chiropractic research and Clinical Investigation, 1991.' },
  { citationNumber: 3, infoText: 'Botelho MB, Andrade BB. Effect of cervical spine manipulative therapy on judo athletes’ grip strength. Journal of Manipulative and Physiological Therapeutics. 2012 January; 35(1):38-44. doi: 10.1016/j.jmpt.2011.09.005.', ariaLabel: 'Botelho MB, Andrade BB. Effect of cervical spine manipulative therapy on judo athletes’ grip strength. Journal of Manipulative and Physiological Therapeutics. 2012 January; 35(1):38-44. doi: 10.1016/j.jmpt.2011.09.005.' },
] 
