import { AwesomeButton } from 'react-awesome-button';
import { ACFOpeningObject } from '../../types/types';


type Props = {
  openingHours: ACFOpeningObject[];
};

const HoursTable = ({ openingHours }: Props) => {
  return (
    <div className="w-full m-4 bg-gray-100 border-2 border-black-100 h-auto p-10 flex flex-col justify-start min-w-[200px] max-w-xl">
      <div className="text-[#846bb9] flex flex-col justify-center items-center">
        <h2 className="text-2xl font-semibold">Opening Hours</h2>
      </div>
      {/* Fees Area */}
      <div className="max-w-4xl">
        {openingHours.map((opening, index) => {
          const { day, time } = opening.acf;
          return (
            <div key={index} className="flex flex-row justify-between py-2 my-4 text-gray-500 border-b-2 border-gray-300">
              <p className="text-sm">{day}</p>
              <p className="text-sm font-bold">{time}</p>
            </div>
          )
        })}
      </div>
      <div className="flex items-center justify-center mt-5">
        <AwesomeButton type="primary" href="bookings">
          Book Online
        </AwesomeButton>
      </div>
    </div>
  );
};

export default HoursTable;
