import { TeamACF } from '../../../types/types'
import TeamMember from '../TeamMember/TeamMember'
import { Fade } from "react-awesome-reveal";

type Props = {
	team: TeamACF[];
}

const TeamMembers = ({ team }: Props) => {
	return (

		<Fade triggerOnce direction='up' cascade damping={0.1}>
			{/* Map over the team array */}
			{team?.map(
				(teamMember: TeamACF, index: number) => {
					// Spread the team member
					const { name, job_title, description, profile_image, active } = teamMember.acf;

					// Condition if team member is no active
					if (!active) return null;
					// Active team returned
					return (
						<div key={index} className="animated-cust">
							<TeamMember
								name={name}
								job_title={job_title}
								description={description}
								profile_image={profile_image}
								active={active}
							/>
						</div>
					)
				}
			)}
		</Fade>

	)
}

export default TeamMembers