import { CornerBanner } from '..';
import { Spin as Hamburger } from 'hamburger-react';

type Props = {
  logo: string;
  setMenuHovering: (menuHovering: boolean) => void;
  menuHovering: boolean;
  navStatus: boolean;
  toggleNavStatus: () => void;
};

const Nav = ({
  logo,
  setMenuHovering,
  menuHovering,
  navStatus,
  toggleNavStatus,
}: Props) => {
  return (
    <nav className="flex  w-full justify-center relative h-[110px] bg-[#40707c] px-5">

      <CornerBanner banner={false} />

      <div className="flex items-center justify-between w-full max-w-5xl">
        {/* Logo */}
        <div className="flex h-[90px]">
          <a href="/" className="items-center">
            <img
              src={logo}
              className="h-[90px] w-auto"
              alt="kenworthy chiropractic"
              loading="lazy"
            />
          </a>
        </div>

        {/* Menu Button */}
        <div className="flex items-center">


          <button
            onMouseEnter={() => {
              setMenuHovering(true);
            }}
            onMouseLeave={() => {
              setMenuHovering(false);
            }}
            onClick={() => toggleNavStatus()}
            type="button"
            className="flex items-center text-xl font-bold text-white h-fit "
          >

            <p className="hidden text-xl font-bold text-white cursor-pointer md:inline">Menu&nbsp;</p>

            {menuHovering && !navStatus ? (
              <Hamburger
                toggled={navStatus}
                toggle={toggleNavStatus}
                size={40}
                direction="right"
                label="Menu"
              />
            ) : (
              <Hamburger
                toggled={navStatus}
                toggle={toggleNavStatus}
                direction="right"
                label="Menu"
              />
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
